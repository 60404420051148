<template>
  <div id="vital1" ref="vital1">
    <div class="index1">
      <div class="box1-title">
        <div class="font">新闻中心</div>
        <div class="border"></div>
      </div>

      <div class="text">
        金华市天华网络科技有限公司成立于2007年，公司主要从事信息行业应用软件开发和技术服务，大数据互联网与物联网、人工智能、云计算、AI等技术，开发大数据综合管理服务云平台。
      </div>
    </div>
    <div class="index2">
      <div class="head_row">
        <div class="flex flex-direction-row tab" v-show="!showDetail">
          <div
              class="tab-item flex flex-align-center flex-justify-content"
              @click="tabChange(item, index)"
              v-for="(item, index) in tabList"
              :key="index"
              :class="activeTab === index ? 'active' : ''"
          >
            <!-- <img v-if="activeTab !== index" :src="item.icon1" alt="" />
            <img v-else :src="item.icon2" alt="" /> -->
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="breadcrumb_box" v-show="showDetail">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>{{ activeCategoryName }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <el-button type="primary" @click="goBack()" v-show="showDetail"
        >返回上一级
        </el-button
        >
      </div>
      <div class="content_row">
        <NewsList
            ref="list"
            :categoryName="activeCategoryName"
            v-show="!showDetail"
            @goDetail="getNewsDetail"
        ></NewsList>
        <NewsDetail v-show="showDetail" ref="detail"></NewsDetail>
      </div>
    </div>
  </div>
</template>

<script>
import NewsList from "@/views/news/modules/newsList.vue";
import NewsDetail from "@/views/news/modules/newsDetail.vue";

export default {
  name: "news",
  components: {
    NewsList,
    NewsDetail,
  },
  data() {
    return {
      activeTab: 0,
      activeCategoryName: "公司新闻",
      title: "",
      tabList: [
        {
          name: "公司新闻",
          value: 0,
          icon1: require("@/assets/news/news-unactive.png"),
          icon2: require("@/assets/news/news-active.png"),
        },
        {
          name: "行业动态",
          value: 1,
          icon1: require("@/assets/news/dynamic-unactive.png"),
          icon2: require("@/assets/news/dynamic-active.png"),
        },
        {
          name: "行业政策",
          value: 2,
          icon1: require("@/assets/news/police-unactive.png"),
          icon2: require("@/assets/news/police-active.png"),
        },
      ],
      showDetail: false,
    };
  },
  watch: {
    "$route.query.categoryId": {
      handler(newVal) {
        if (newVal) {
          this.activeTab = newVal;
          this.activeCategoryName =
              newVal == 0 ? "公司新闻" : newVal == 1 ? "行业动态" : "行业政策";
        } else {
          this.activeTab = 0;
          this.activeCategoryName = "公司新闻";
        }
      },
    },
  },
  created() {
    let test222 = this.$route.query.categoryId;
    if (this.$route.query.categoryId && this.$route.query.categoryId != 0) {
      this.activeCategoryName =
          this.$route.query.categoryId == 1 ? "行业动态" : "行业政策";
      this.activeTab = this.$route.query.categoryId;
    }
  },
  mounted() {
    this.$nextTick(() => {
      let id = sessionStorage.getItem("newsDetailId");
      let categoryName = sessionStorage.getItem("newsDetailCategory");
      let newsTitle = sessionStorage.getItem("newsDetailTitle");
      if (id) {
        this.activeCategoryName = categoryName;
        this.title = newsTitle;
        this.getNewsDetail();
      }
    });
  },
  methods: {
    tabChange(item, index) {
      this.activeTab = index;
      this.activeCategoryName = item.name;
    },
    getNewsDetail(item) {
      this.showDetail = true;
      this.title = sessionStorage.getItem("newsDetailTitle");
      document.documentElement.scrollTop = 0;
      this.$refs.detail.getDetail();
      // this.$nextTick(() => {
      //   this.$refs.vital1.scrollTop = 0;
      // });
    },
    goBack() {
      let newsDetailCategory = sessionStorage.getItem("newsDetailCategory");
      let detailId = sessionStorage.getItem("newsDetailId");
      this.activeTab = newsDetailCategory == "公司新闻" ? 0 : newsDetailCategory == "行业动态" ? 1 : 2;
      this.activeCategoryName = newsDetailCategory;
      sessionStorage.removeItem("newsDetailId");
      sessionStorage.removeItem("newsDetailCategory");
      sessionStorage.removeItem("newsDetailTitle");
      this.showDetail = false;
    },
  },
  beforeDestroy() {
    sessionStorage.removeItem("newsDetailId");
    sessionStorage.removeItem("newsDetailCategory");
    sessionStorage.removeItem("newsDetailTitle");
  },
};
</script>

<style lang="scss" scoped>
.index1 {
  height: 609px;
  background: url("https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/news-banner.png") no-repeat center center;
  background-size: 100% 100%;
  text-align: left;
  padding-left: 210px;
  padding-right: 210px;
}

.box1-title {
  padding-top: 155px;
  margin-bottom: 16px;
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
  line-height: 33px;

  .font {
    position: relative;
    z-index: 10;
  }
}

.border {
  width: 93px;
  height: 10px;
  background: linear-gradient(270deg, #014dd6 0%, rgba(1, 77, 214, 0) 100%);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  margin-left: 15px;
  margin-top: -5px;
  position: relative;
  z-index: 9;
  right: -16px;
  bottom: 0px;
}

.text {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  line-height: 23px;
  text-indent: 2em;
}

.index2 {
  margin: 0 auto;
  margin-bottom: 50px;
  box-sizing: border-box;
  margin-top: -330px;
  width: 1500px;
  // border-radius: 10px;
  // background-color: rgba(255, 255, 255, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  // box-shadow: 0px 0px 10px 0px rgba(52, 111, 255, 0.3);
  padding: 33px 40px 66px 40px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 6px 11px 4px rgba(32, 89, 192, 0.07);
  opacity: 1;

  .head_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .content_row {
    // margin-top: 30px;
  }

  .tab {
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;

    font-size: 20px;
    line-height: 23px;

    // margin-bottom: 52px;

    .tab-item {
      color: #666;
      margin-right: 30px;
      user-select: none;
      padding-bottom: 6px;

      img {
        width: 25px;
        vertical-align: middle;
        margin-right: 5px;
      }

      &.active {
        color: rgba(52, 111, 255, 1);
        font-weight: bold;
        border-bottom: 2px solid #217eff;
      }
    }
  }
}

#vital1 {
  background: #ffffff;
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
}
</style>
