<template>
  <div>
    <div class="title">
      {{ news.title }}
    </div>
    <div class="time">编辑于：{{ news.createDate }}</div>
    <div class="line"></div>
    <div v-html="news.publishContent" class="news_detail"></div>
  </div>
</template>

<script>
import { getNewsDetail } from "@/api/home";
export default {
  name: "newsDetail",

  data() {
    return {
      news: {},
    };
  },

  create() {},
  mounted() {},
  methods: {
    //获取数据
    getDetail() {
      let id = sessionStorage.getItem("newsDetailId");
      getNewsDetail({ id: id }).then((res) => {
        this.news = res.result;
        this.news.publishContent = this.news.publishContent.replaceAll(
          "&nbsp;",
          ""
        );
        // this.news.publishContent = this.formatRichText(
        //   this.news.publishContent
        // );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  text-align: left;
  font-weight: bold;
}
.time {
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  text-align: left;
  margin-top: 15px;
}
.line {
  width: 100%;
  border: 0.5px dashed rgba(52, 111, 255, 0.2);
  margin: 15px 0px;
}
::v-deep .news_detail {
  color: rgba(51, 51, 51, 1);
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  line-height: 29px;
  text-indent: 36px;
  img {
    width: 50% !important;
    height: auto !important;
    display: block !important;
    margin: 10px auto;
  }
  br {
    display: none;
  }
}
</style>
