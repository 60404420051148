<template>
  <div class="flex flex-wrap flex-between">
<!--          <div v-for="item in newsList" :key="item.id" class="news_row">-->
<!--            <img :src="item.contentPic" alt=""/>-->
<!--            <div class="content_box flex-between">-->
<!--              <div class="title">{{ item.title }}</div>-->
<!--              &lt;!&ndash;        <div class="summary">&ndash;&gt;-->
<!--              &lt;!&ndash;          {{ item.summary ? item.summary : item.title }}&ndash;&gt;-->
<!--              &lt;!&ndash;        </div>&ndash;&gt;-->
<!--              <div class="time">-->
<!--                <div>{{ item.createDate }}</div>-->
<!--                <el-button type="primary" @click="goDetail(item)">查看详情</el-button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
    <div v-for="row in rowCount" class="row">
      <div
          v-for="(item, index) in newsList.slice(
          (row - 1) * columnCount,
          row * columnCount
        )"
          class="card_box"
          :id="item.id + '_box'"
      >
        <el-image :src="item.contentPic" fit="cover">
          <div slot="error" class="image-slot">
            <img src="../../../assets/casus/error.jpg" class="error_img"/>
          </div>
        </el-image>
        <div class="content_box">
          <div class="title">
            <div>{{ item.title }}</div>
          </div>
          <div class="box_foot">
            <div>{{ item.createDate }}</div>
            <el-button type="primary" @click="goDetail(item)"
            >查看详情<i class="el-icon-arrow-right"></i
            ></el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentNum"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="10"
          layout="total, sizes, prev, pager, next"
          :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {getNews} from "@/api/home";

export default {
  name: "newsList",
  props: {
    categoryName: {
      type: String,
      default: "公司新闻",
    },
  },
  data() {
    return {
      //一行2列
      columnCount: 2,
      //总行数
      rowCount: 0,
      currentPage: 1,
      total: 0,
      pageSize: 10,
      currentNum: 1,
      newsList: [],
    };
  },
  watch: {
    //方法1
    categoryName(newVal, oldVal) {
      this.currentNum = 1;
      this.getData();
    },
  },

  create() {
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
    });
  },
  methods: {
    //获取数据
    getData() {
      let params = {
        categoryFlag: 2,
        categoryName: this.categoryName,
        pageNum: this.currentNum,
        pagesize: this.pageSize,
      };
      getNews(params).then((res) => {
        this.total = res.result.total;
        this.newsList = res.result.list;
        this.rowCount = Math.ceil(this.newsList.length / this.columnCount);
      });
    },
    goDetail(item) {
      sessionStorage.setItem("newsDetailId", item.id);
      sessionStorage.setItem("newsDetailCategory", item.categoryName);
      sessionStorage.setItem("newsDetailTitle", item.title);
      this.$emit("goDetail", item);
    },
    changeData(categoryName) {
      this.getData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentNum = val;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(51, 51, 51, .1);
}

.card_box {
  width: 46%;
  height: 200px;
  line-height: 20px;
  border-radius: 10px;
  //background-color: rgba(255, 255, 255, 1);
  text-align: center;
  //box-shadow: 5px 5px 5px 0px rgba(52, 111, 255, 0.2);
  //border: 1px solid rgba(52, 111, 255, 0.1);
  margin-top: 30px;
  padding: 20px;
  display: flex;
  flex-direction: row;

  img {
    width: 315px;
    height: 210px;
    margin-right: 20px;
  }

  .content_box {
    display: flex;

    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    .title {
      color: rgba(51, 51, 51, 1);
      font-size: 18px;
      text-align: left;
      font-weight: bold;
    }

    .box_foot {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: rgba(153, 153, 153, 1);
      font-size: 14px;
      text-align: left;
    }
  }
}

::v-deep .el-image {
  img {
    width: 315px;
    height: 210px;
    margin-right: 15px;
    //border-radius: 10px;
  }

  .error_img {
    width: 315px;
    height: 210px;
    margin-right: 15px;
    //border-radius: 10px;
  }
}

.page {
  width: 100%;
  margin-top: 30px;
}

.news_row {
  width: 47%;
  height: 200px;
  // background: #ffffff;
  opacity: 1;
  display: flex;
  padding: 30px 0;
  //border-bottom: 1px solid #c1c1c1;

  img {
    width: 315px;
    height: 210px;
    margin-right: 20px;
  }

  .content_box {
    display: flex;
    flex-direction: column;
    flex: 1;

    .title {
      height: 33px;
      font-size: 22px;
      font-weight: 500;
      color: #333333;
      line-height: 26px;
      text-align: left;
    }

    .summary {
      height: 31px;
      font-size: 18px;
      font-weight: 400;
      color: #666666;
      line-height: 21px;
      flex: 1;
      text-align: left;
      margin-top: 10px;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
    }

    .time {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      align-items: center;
    }
  }
}
</style>
