import request from "@/utils/request";
import qs from "qs";

export function queryHonorList(params) {
  return request({
    url: "/content/getImgList",
    method: "post",
  });
}

export function getDetail(id) {
  return request({
    url: `/api/vermin/getById?id=${id}`,
    method: "get",
  });
}

export function getNews(params) {
  let dataObj = qs.stringify(params);
  return request({
    url: "/content/getPageList",
    method: "post",
    data: dataObj,
  });
}
export function getNewsDetail(params) {
  let dataObj = qs.stringify(params);
  return request({
    url: "/content/getContentById",
    method: "post",
    data: dataObj,
  });
}
